<template>
  <div class="wrap">
    <NavBar :title="$t('pages.editEmail')" left-arrow @click-left="$router.go(-1)"></NavBar>
    <Form @submit="onSubmit">
      <Field v-model="emailValue" name="email" :label="$t('editEmail.label')" :placeholder="$t('editEmail.placeholder')"
        :rules="rules" />
      <div style="margin: 16px">
        <Button round block type="info" native-type="submit" class="submit">{{
          $t("Button.submit")
        }}</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { NavBar, Field, Form, Button } from "vant";
import { $post, emailRule } from "@/utils";
export default {
  components: {
    NavBar,
    Field,
    Form,
    Button,
  },
  data() {
    return {
      email: "",
      rules: [
        { required: true, message: this.$t('editEmail.hintRequire') },
        { validator: this.emailValidator, message: this.$t('editEmail.hintCorrect') }
      ]
    };
  },
  methods: {
    emailValidator(val) {
      return emailRule.test(val)
    },
    async onSubmit(values) {
      if (!values.email) return;
      try {
        const res = await $post("/users/email/bind", {
          email: values.email,
        });
        this.$store.dispatch("getUserInfo");
        this.$router.go(-1);
      } catch (error) { }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    emailValue: {
      get() {
        return this.email || this.userInfo.email;
      },
      set(val) {
        this.email = val;
        return val;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.submit {
  background-color: var(--newBtn);
  color: var(--btnsizeColor);
  border: none;
}

.van-cell {
  background-color: var(--bg);

  /deep/ .van-field__label {
    color: var(--theme);
  }

  /deep/ .van-field__control {
    color: var(--theme);
  }
}

.van-cell::after {
  border-bottom: none;
}
</style>